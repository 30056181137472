import React from 'react';
import Carousel from 'better-react-carousel';
import style from './index.module.scss';
import SliderItem from './SliderItem/SliderItem.jsx';
import ModalSlider from './ModalSlider/ModalSlider.jsx';
import { images } from './dataElementSlider';

export default function ProblemSlider({ toggleModal }) {
	const [openModal, setOpenModal] = React.useState(false); // модальное окно слайдера
	const [dataModal, setDataModal] = React.useState({}); // стейт данных активной картинки

	// передаем данные в модальное окно
	const toggleModalSlider = event => {
		setOpenModal(!openModal);
		setDataModal(event);
	};

	return (
		<>
			{openModal && (
				<ModalSlider
					toggleModal={toggleModal}
					toggleModalSlider={toggleModalSlider}
					dataModal={dataModal}
				/>
			)}
			<div className={style.problemSlider}>
				<div className={style.inner}>
					<div className={style.title}>
						Мастер проводит профессиональное лечение более 8 проблем:
					</div>
					<div className={style.line}></div>
					<div className={style.sliderContainer}>
						<div className={style.slider}>
							<Carousel cols={4} rows={2} gap={10} hideArrow={true} loop>
								{images.map(el => (
									<Carousel.Item key={el.id}>
										<SliderItem
											dataElement={el}
											toggleModalSlider={toggleModalSlider}
											openModal={openModal}
										/>
									</Carousel.Item>
								))}
							</Carousel>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
