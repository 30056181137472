/* eslint-disable no-undef */
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import ModalPolicy from '../ModalPolicy/ModalPolicy.jsx';
import video from '../../assets/videos/video.mp4';
import { getUnMaskPhone } from '../../utils/maskPhone';
import style from '../TopScreenVideo/index.module.scss';
import SignOnline from '../SignOnline/SignOnline.jsx';

export default function TopScreenVideo({ landingData, toggleModal }) {
	console.log('landingData', landingData);
	let yandex;

	try {
		yandex = landingData?.metrika[0]?.yandex;
	} catch {
		yandex = '';
	}
	const navigation = useNavigate();
	const [showModalPolicy, setShowModalPolicy] = useState(false); // стейт модального окна политики конфиденциальности

	const [checkbox, setCheckbox] = useState(true); // стейт чекбокса

	// получаем и записываем в стейт данные клиента
	const handleUser = (event) => {
		event.preventDefault();
		const nameClient = event.target.name.value;
		const phone = event.target.phone.value;
		const newPhone = getUnMaskPhone(phone);

		if (checkbox && phone) {
			let newUserData = {
				name: 'Клиент: ' + nameClient,
				phone: newPhone,
				department: landingData.department,
				status: 1,
				notes: '',
				city: landingData.city,
				email: '',
				url: window.location.href,
			};

			fetch(
				'https://onshorkin.com/i8fd90sd/v09cxvjksdf/xzs9/sda9/service_request/',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json;charset=utf-8',
					},
					body: JSON.stringify(newUserData),
				}
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					ym(yandex, 'reachGoal', 'SendingCallbackForm');
					_tmr.push({
						type: 'reachGoal',
						id: 3314607,
						value: 900,
						goal: 'otpravkazayavki',
					});
				})
				.catch((e) => {
					console.log(e);
					navigation('/error');
				});
			navigation('/sent');
		}
	};

	// переключение модального окна
	const toggleModalPolicy = () => {
		setShowModalPolicy(!showModalPolicy);
	};
	// переключение чекбокса
	const switchCheck = () => {
		setCheckbox(!checkbox);
	};

	// объект с name "head_price"
	const headPriceObject = landingData?.head?.find(
		(item) => item.name === 'head_price'
	);
	// значение value head_price
	const headPriceValue = headPriceObject ? headPriceObject.value : null;

	// объект с name "head_price_crossline"
	const headPriceCrosslineObject = landingData?.head?.find(
		(item) => item.name === 'head_price_crossline'
	);
	// значение value head_price_crossline
	const headPriceCrosslineValue = headPriceCrosslineObject
		? headPriceCrosslineObject.value
		: null;

	return (
		<div className={style.topScreenVideo}>
			{showModalPolicy && (
				<ModalPolicy
					landingData={landingData}
					toggleModalPolicy={toggleModalPolicy}
				/>
			)}
			<div className={style.containerVideo}>
				<video
					className={style.backgroundVideo}
					loop
					muted
					autoPlay
					playsInline
					disablePictureInPicture
					preload='auto'
				>
					<source src={video} type='video/mp4' />
				</video>
			</div>
			<div className={style.inner}>
				<div className={style.wrapper}>
					<header className={style.header}>
						<h1 className={style.title}>
							Женский и мужской медицинский аппаратный педикюр
						</h1>
						<h2 className={style.subtitle}>
							Избавьтесь от 9 проблем с ногами <br />
							от <span className={style.struck}>
								{headPriceCrosslineValue}
							</span>{' '}
							{headPriceValue} руб!
						</h2>
						<h3 className={style.text}>В эту цену входит:</h3>
						<ul>
							<li>Глубокие трещины</li>
							<li>Снятие покрытия</li>
							<li>Первичная обработка вросшего ногтя</li>
							<li>Установка тампонады</li>
							<li>Стержневые мозоли</li>
							<li>Грибок большого пальца</li>
						</ul>
					</header>
					<form
						onSubmit={handleUser}
						className={style.topScreenForm}
						action='#'
					>
						<div className={style.title}>
							Оставьте заявку и получите аппаратный массаж ног в подарок!
						</div>
						<div className={style.subtitle}></div>
						<div className={style.inputs}>
							<input
								className={style.input}
								type='text'
								name='name'
								id='name'
								placeholder='Имя'
							/>
							<InputMask
								className={style.input}
								mask='+7 (999) 999-99-99'
								type='tel'
								name='phone'
								id='phone'
								placeholder='+7 (___) ___-__-__'
								required
							/>

							<button type='submit' className={style.submit}>
								Получить подарок
							</button>
							<div onChange={switchCheck} className={style.check}>
								<div
									className={
										checkbox ? style.checkboxStyleActive : style.checkboxStyle
									}
								></div>
								<input
									type='checkbox'
									name='checkbox'
									id='checkbox'
									defaultChecked
									required
									className={style.checkbox}
								/>
								<label className={style.text} htmlFor='checkbox'>
									Подтверждаю, что я ознакомлен с{' '}
									<span onClick={toggleModalPolicy} className={style.link}>
										политикой конфиденциальности
									</span>{' '}
									и даю свое согласие на сбор и обработку персональных данных
								</label>
							</div>

							<SignOnline landingData={landingData} inline={true} />
						</div>
					</form>
					<div className={style.mobileAppeal}>
						<div className={style.title}>
							Оставьте заявку и получите аппаратный массаж ног в подарок!
						</div>
						<div onClick={toggleModal} className={style.button}>
							Получить подарок
						</div>
						<SignOnline landingData={landingData} inline={true} />
					</div>
				</div>
			</div>
		</div>
	);
}
