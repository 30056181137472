import React from 'react';
import style from './index.module.scss';

export default function ModalPolicy({ landingData, toggleModalPolicy }) {
	return (
		<>
			<div className={style.background} onClick={toggleModalPolicy}></div>
			<div className={style.container}>
				<div className={style.modal}>
					<div className={style.form}>
						<div className={style.close} onClick={toggleModalPolicy}>
							×
						</div>
						<div className={style.title}>
							Политика оператора в отношении обработки персональных данных
						</div>

						<div className={style.description}>
							<p>
								<strong>Введение</strong>
							</p>
							<p className={style.width}>
								1.1. Важнейшим условием реализации целей деятельности{' '}
								{landingData.company_info} (далее {landingData.company_info},
								либо Оператор) является обеспечение необходимого и достаточного
								уровня информационной безопасности информации, к которой, в том
								числе, относятся персональные данные.
							</p>
							<p className={style.width}>
								1.2. Политика в отношении обработки персональных данных в{' '}
								{landingData.company_info} определяет порядок сбора, хранения,
								передачи и иных видов обработки персональных данных в{' '}
								{landingData.company_info}, а также сведения о реализуемых
								требованиях к защите персональных данных.
							</p>
							<p className={style.width}>
								<em>
									{landingData.company_info} обрабатывает персональные данные
									следующих лиц:
								</em>
							</p>
							<p className={style.width}>
								<em>- пользователей сайта {landingData.company_info};</em>
							</p>
							<p className={style.width}>
								<em>
									-субъектов, с которыми заключены договоры гражданско-правового
									характера;
								</em>
							</p>
							<p className={style.width}>
								<em>
									-кандидатов на замещение вакантных должностей ООО "НК
									Росинновации", работников {landingData.company_info}.
								</em>
							</p>
							<p className={style.width}>
								1.3.Субъект персональных данных принимает решение о
								предоставлении его персональных данных и дает согласие на их
								обработку свободно, своей волей и в своем интересе.
							</p>
							<p className={style.width}>
								1.4. Политика разработана в соответствии с действующим
								законодательством РФ. Политика обработки персональных данных
								Оператора определяется, в том числе, в соответствии со
								следующими нормативными правовыми актами:
							</p>
							<ul>
								<li>
									<p className={style.width}>Конституция РФ;</p>
								</li>
								<li>
									<p className={style.width}>
										Федеральный закон от 27 июля 2006 г. № 152-ФЗ «О
										персональных данных»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										Глава 14 (ст. 85-90) Трудового кодекса РФ;
									</p>
								</li>
								<li>
									<p className={style.width}>Налоговый кодекс РФ;</p>
								</li>
								<li>
									<p className={style.width}>
										Федеральный закон от 21.11.2011 № 323-ФЗ «Об основах охраны
										здоровья граждан в Российской Федерации»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										Федеральный закон от 01.04.1996 № 27-ФЗ «Об индивидуальном
										(персонифицированном) учете в системе обязательного
										пенсионного страхования»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										Указ Президента Российской Федерации от 06 марта 1997 г. №
										188 «Об утверждении Перечня сведений конфиденциального
										характера»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										постановление Правительства Российской Федерации от 15
										сентября 2008 г. № 687 «Об утверждении Положения об
										особенностях обработки персональных данных, осуществляемой
										без использования средств автоматизации»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										постановление Правительства Российской Федерации от 6 июля
										2008 г. № 512 «Об утверждении требований к материальным
										носителям биометрических персональных данных и технологиям
										хранения таких данных вне информационных систем персональных
										данных»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										постановление Правительства Российской Федерации от 1 ноября
										2012 г. № 1119 «Об утверждении требований к защите
										персональных данных при их обработке в информационных
										системах персональных данных»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										приказ ФСТЭК России № 55, ФСБ России № 86, Мининформсвязи
										России № 20 от 13 февраля 2008 г. «Об утверждении Порядка
										проведения классификации информационных систем персональных
										данных»;
									</p>
								</li>
								<li>
									<p className={style.width}>
										приказ Роскомнадзора от 05 сентября 2013 г. № 996 «Об
										утверждении требований и методов по обезличиванию
										персональных данных».
									</p>
								</li>
							</ul>
							<p>
								<strong>Принципы обработки персональных данных</strong>
							</p>
							<p className={style.width}>
								2.1. При обработке персональных данных субъектов реализуются
								следующие принципы:
							</p>
							<p className={style.width}>
								-соблюдение законности получения, обработки, хранения, а так же
								других действий с персональными данными;
							</p>
							<p className={style.width}>
								-обработка персональных данных исключительно с целью исполнения
								обязательств по договору оказания услуг;
							</p>
							<p className={style.width}>
								-сбор только тех персональных данных, которые минимально
								необходимы для достижения заявленных целей обработки;
							</p>
							<p className={style.width}>
								-выполнение мер по обеспечению безопасности персональных данных
								при их обработке и хранении;
							</p>
							<p className={style.width}>
								-соблюдение прав субъекта персональных данных на доступ к его
								персональным данным.
							</p>
							<p className={style.width}>
								2.2. Обработка персональных данных Оператором осуществляется с
								учетом необходимости обеспечения защиты прав и свобод субъектов
								персональных данных, в том числе защиты права на
								неприкосновенность частной жизни, личную и семейную тайну, на
								основе следующих принципов:
							</p>
							<ul>
								<li>
									<p className={style.width}>
										обработка персональных данных осуществляется на законной и
										справедливой основе;
									</p>
								</li>
								<li>
									<p className={style.width}>
										обработка персональных данных ограничивается достижением
										конкретных, заранее определенных и законных целей;
									</p>
								</li>
								<li>
									<p className={style.width}>
										не допускается обработка персональных данных, несовместимая
										с целями сбора персональных данных;
									</p>
								</li>
								<li>
									<p className={style.width}>
										не допускается объединение баз данных, содержащих
										персональные данные, обработка которых осуществляется в
										целях, несовместимых между собой;
									</p>
								</li>
								<li>
									<p className={style.width}>
										обработке подлежат только персональные данные, которые
										отвечают целям их обработки;
									</p>
								</li>
								<li>
									<p className={style.width}>
										содержание и объем обрабатываемых персональных данных
										соответствует заявленным целям обработки. Не допускается
										избыточность обрабатываемых персональных данных по отношению
										к заявленным целям их обработки;
									</p>
								</li>
								<li>
									<p className={style.width}>
										при обработке персональных данных обеспечиваются точность
										персональных данных, их достаточность, а в необходимых
										случаях и актуальность по отношению к целям обработки
										персональных данных. Оператором принимаются необходимые меры
										либо обеспечивается их принятие по удалению или уточнению
										неполных или неточных персональных данных;
									</p>
								</li>
								<li>
									<p className={style.width}>
										хранение персональных данных осуществляется не дольше, чем
										того требуют цели обработки персональных данных, если срок
										хранения персональных данных не установлен федеральным
										законом, договором, стороной которого, выгодоприобретателем
										или поручителем по которому является субъект персональных
										данных;
									</p>
								</li>
								<li>
									<p className={style.width}>
										обрабатываемые персональные данные уничтожаются либо
										обезличиваются по достижении целей обработки или в случае
										утраты необходимости в достижении этих целей, если иное не
										предусмотрено федеральным законом.
									</p>
								</li>
							</ul>
							<p className={style.width}>
								Оператор, получивший доступ к персональным данным, обязан не
								раскрывать третьим лицам и не распространять персональные данные
								без согласия субъекта персональных данных, если иное не
								предусмотрено федеральным законом.
							</p>
							<p>
								<strong>Состав персональных данных</strong>
							</p>
							<p className={style.width}>
								3.1. Сведениями, составляющими персональные данные, является
								любая информация, относящаяся к прямо или косвенно определенному
								или определяемому физическому лицу (субъекту персональных
								данных).
							</p>
							<p className={style.width}>
								3.2. Все обрабатываемые Оператором персональные данные являются
								конфиденциальной, строго охраняемой информацией в соответствии с
								законодательством РФ.
							</p>
							<p className={style.width}>
								3.3. Информацией, относящейся к персональным данным,
								используемой Оператором исключительно для обратной связи с
								клиентом, является номер телефона субъекта персональных данных,
								а также Имя субъекта персональных данных.
							</p>
							<p className={style.width}>
								Данный состав персональных данных определен только в части
								использования сайта Оператора и его сервисов субъектом
								персональных данных (пользователем сайта).
							</p>
							<p className={style.width}>
								3.4. Информацией, относящейся к персональным данным,
								используемой Оператором в связи с заключением договора на
								оказание услуг, стороной которого является субъект персональных
								данных, без распространения персональных данных, без
								предоставления третьим лицам, исключительно для исполнения
								указанного договора и заключения договора с субъектом
								персональных данных:
							</p>
							<p className={style.width}>
								— имя, отчество и фамилия (при наличии);
							</p>
							<p className={style.width}>— дата рождения;</p>
							<p className={style.width}>— номер телефона;</p>
							<p className={style.width}>—состояние здоровья;</p>
							<p className={style.width}>
								—паспортные данные (тип документа, серия, номер, кем выдан,
								адрес регистрации)
							</p>
							<p className={style.width}>
								3.5. Информацией, относящейся к персональным данным,
								используемой Оператором в связи с трудоустройством граждан к
								Оператору, кадровым делопроизводством работников Оператора,
								ведением бухгалтерского учета и отчетности:
							</p>
							<p className={style.width}>
								— имя, отчество и фамилия (при наличии);
							</p>
							<p className={style.width}>
								—паспортные данные (тип документа, серия, номер, кем выдан,
								адрес регистрации);
							</p>
							<p className={style.width}>
								— документ об образовании, стаже работы.
							</p>
							<p>
								<strong>Цели обработки персональных данных</strong>
							</p>
							<p className={style.width}>
								4.1 Персональные данные обрабатываются Оператором в целях
								оформления договорных отношений, по основаниям, предусмотренным
								ст.22 Федерального закона от 27.06.2006 №152-ФЗ, Гражданским
								кодексом РФ, оказания иных, в том числе консультационных, услуг
								субъектам персональных данных по вопросам приобретения товара,
								услуги, использования сервисов сайта Оператора; продвижения
								услуг и/или товаров Оператора, осуществления прямых контактов с
								клиентами Оператора с помощью средств связи, а также по
								основаниям, предусмотренным ст.ст. 85-90 Трудового кодекса РФ, в
								целях трудоустройства граждан к Оператору, ведения кадрового
								делопроизводства, бухгалтерского учета и отчетности.
							</p>
							<p className={style.width}>
								4.2. Оператор в целях надлежащего исполнения своих обязанностей
								Оператора осуществляет сбор, обработку, хранение, уничтожение
								следующих персональных данных:
							</p>
							<p className={style.width}>
								- номер телефона субъекта; Имя субъекта*
							</p>
							<p className={style.width}>
								<em>
									(в части использования сайта и сервисов сайта Оператора);
								</em>
							</p>
							<p className={style.width}>
								— имя, отчество и фамилия (при наличии);
							</p>
							<p className={style.width}>— дата рождения;</p>
							<p className={style.width}>— номер телефона;</p>
							<p className={style.width}>—состояние здоровья</p>
							<p className={style.width}>
								—паспортные данные (тип документа, серия, номер, кем выдан,
								адрес регистрации)*
							</p>
							<p className={style.width}>
								<em>(в части оформления договорных отношений);</em>
							</p>
							<p className={style.width}>
								— имя, отчество и фамилия (при наличии);
							</p>
							<p className={style.width}>
								—паспортные данные (тип документа, серия, номер, кем выдан,
								адрес регистрации);
							</p>
							<p className={style.width}>
								— документ об образовании, стаже работы*
							</p>
							<p className={style.width}>
								<em>
									(в части оформления трудовых отношений работников Оператора,
									ведения кадрового делопроизводства)
								</em>
							</p>
							<p className={style.width}>
								4.3. Вышеизложенные в п. 3.3 персональные данные субъект
								персональных данных вводит на сайте Оператора сети Интернет в
								специальном окне для обратной связи. Введение персональных
								данных: номера телефона, Имени - не являются обязательными
								условиями связи с Оператором, поскольку номер телефона Оператора
								указан на сайте Оператора и является доступным для
								неограниченного круга лиц. Обратная связь с посетителем сайта
								используется по желанию посетителя сайта. Персональные данные
								посетителя сайта при заполнении им формы Обратной связи
								используются Оператором исключительно для телефонной связи
								Оператора с посетителем для целей, указанных выше. Указанные
								персональные данные являются конфиденциальными, не подлежащими
								передаче третьим лицам, подлежащими хранению.
							</p>
							<p className={style.width}>
								Персональные данные передаются субъектом персональных данных при
								использовании сайта Оператора при оформлении услуги Обратной
								связи исключительно по желанию субъекта персональных данных по
								адресу: {window.location.href} при подтверждении согласия на
								обработку персональных данных.
							</p>
							<p className={style.width}>
								Введенные по п. 3.3. пользователем сайта персональные данные
								(имя, номер телефона) не являются для Оператора целью для
								установления его идентификации, установления личности
								пользователя, но используются исключительно для возможности
								установления телефонной связи с пользователем сайта.
							</p>
							<p className={style.width}>
								Вышеизложенные в п. 3.4 персональные данные субъект персональных
								данных предоставляет при дачи и подтверждении своего письменного
								согласия на сбор, обработку персональных данных с целью
								заключения договорных отношений, оказания услуг в рамках
								договора, исключительно для исполнения договора с субъектом
								персональных данных. Сбор, обработка, хранение, уничтожение
								персональных данных осуществляется Оператором на основании
								Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных
								данных», Гражданского кодекса РФ, Федерального закона от
								21.11.2011 № 323-ФЗ «Об основах охраны здоровья граждан в
								Российской Федерации».
							</p>
							<p className={style.width}>
								Вышеизложенные в п. 3.5 персональные данные субъект персональных
								данных предоставляет при трудоустройстве к Оператору, являясь
								кадровым работником оператора, после письменного подтверждения
								согласия на сбор, обработку персональных данных. Получение
								персональных данных преимущественно осуществляется путем
								представления их самим гражданином или работником, на основании
								его письменного согласия, за исключением случаев прямо
								предусмотренных действующим законодательством РФ. Сбор,
								обработка, хранение, уничтожение персональных данных
								осуществляется Оператором на основании Федерального закона от 27
								июля 2006 г. № 152-ФЗ «О персональных данных», Налогового
								кодекса РФ, Федерального закона от 01.04.1996 № 27-ФЗ «Об
								индивидуальном (персонифицированном) учете в системе
								обязательного пенсионного страхования», ст.ст. 85-90 Трудового
								кодекса РФ.
							</p>
							<p className={style.width}>
								4.4.Оператор осуществляет сбор, обработку, хранение, передачу
								информации о персональных данных, кроме:
							</p>
							<p className={style.width}>
								- обработки специальных категорий персональных данных,
								касающихся расовой, национальной принадлежности, политических
								взглядов, религиозных или философских убеждений, состояния
								здоровья, интимной жизни (за исключением п. 3.4 в части
								состояния здоровья при оформлении договорных отношений);
							</p>
							<p className={style.width}>
								- обработки сведений, которые характеризуют физиологические
								особенности человека и на основе которых можно установить его
								личность (биометрические персональные данные);
							</p>
							<p className={style.width}>
								- трансграничной передачи персональных данных на территории
								иностранных государств, не обеспечивающих адекватной защиты прав
								субъектов персональных данных;
							</p>
							<p className={style.width}>
								- создания общедоступных источников персональных данных (в том
								числе справочники, адресные книги).
							</p>
							<p className={style.width}>
								<strong>
									Сбор, обработка, хранение, уничтожение персональных данных
								</strong>
							</p>
							<p className={style.width}>
								5.1. Оператор осуществляет сбор, запись, систематизацию,
								накопление, хранение, уточнение (обновление, изменение),
								извлечение, использование, обезличивание, блокирование, удаление
								и уничтожение персональных данных.
							</p>
							<p className={style.width}>
								Обработка персональных данных Оператором осуществляется
								следующими способами:
							</p>
							<p className={style.width}>
								- неавтоматизированная обработка персональных данных;
							</p>
							<p className={style.width}>
								- автоматизированная обработка персональных данных с передачей
								полученной информации по информационно-телекоммуникационным
								сетям или без таковой.
							</p>
							<p className={style.width}>
								5.2.Сведения о местонахождении базы данных информации,
								содержащей персональные данные: Российская Федерация.
							</p>
							<p className={style.width}>
								5.3. Обработка персональных данных, осуществляемая без
								использования средств автоматизации, осуществляется таким
								образом, чтобы в отношении каждой категории персональных данных
								можно было определить места хранения персональных данных
								(материальных носителей). Оператором установлен перечень лиц,
								осуществляющих обработку персональных данных либо имеющих к ним
								доступ. Обеспечивается раздельное хранение персональных данных
								(материальных носителей). Оператор обеспечивает сохранность
								персональных данных и принимает меры, исключающие
								несанкционированный доступ к персональным данным. При хранении
								персональных данных соблюдаются организационные и технические
								меры, обеспечивающие их сохранность. Неавтоматизированная
								обработка персональных данных осуществляется без передачи по
								внутренней сети Оператора, без передачи по сети Интернет.
							</p>
							<p className={style.width}>
								5.4. Обработка персональных данных, осуществляемая с
								использованием средств автоматизации, проводится при условии
								выполнения следующих действий: Оператор проводит технические
								мероприятия, направленные на предотвращение несанкционированного
								доступа к персональным данным и (или) передачи их лицам, не
								имеющим права доступа к такой информации; защитные инструменты
								настроены на своевременное обнаружение фактов
								несанкционированного доступа к персональным данным; технические
								средства автоматизированной обработки персональных данных
								изолированы в целях недопущения воздействия на них, в результате
								которого может быть нарушено их функционирование.
							</p>
							<p className={style.width}>
								Оператор обязан сообщить в порядке, предусмотренном статьей 14
								Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных
								данных», субъекту персональных данных или его представителю
								информацию о наличии персональных данных, относящихся к
								соответствующему субъекту персональных данных, а также
								предоставить возможность ознакомления с этими персональными
								данными при обращении субъекта персональных данных или его
								представителя либо в течение тридцати дней с даты получения
								запроса субъекта персональных данных или его представителя. По
								требованию субъекта персональных данных информация в любой
								момент может быть удалена.
							</p>
							<p className={style.width}>
								Хранение персональных данных осуществляется в соответствии с
								требованиями законодательства РФ, внутренними локальными актами
								Оператора.
							</p>
							<p className={style.width}>
								5.5. Оператор использует на Сайтах cookies и подобные
								инструменты для повышения производительности и удобства
								пользования Сайтом. Сайт и сторонние поставщики услуг могут
								использовать на Сайте cookies. Cookies позволяют веб-серверу
								передавать данные на компьютер для хранения и других целей.
								Технология "сookies" не содержит никаких личных сведений
								относительно Пользователя. Данные "Cookies" необходимы для
								настройки Сайта, в том числе для сохранения пользовательских
								установок просмотра и сбора статистической информации по Сайту.
								Кроме того, Cookies и другие технологии используются, в
								частности, для того, чтобы обеспечить более высокий уровень
								обслуживания, предоставить более полную информацию (посещаемость
								Сайта, статистика и пр.), обеспечить пользователю возможность
								постоянно пользоваться Сайтом. Поставщикам услуг также разрешено
								использовать cookies на Сайте. Если пользователь не нуждается в
								информации, полученной с помощью cookies, он может отказаться от
								использования cookies – это стандартная функция, которая есть
								почти во всех браузерах. Также данную технологию использует
								установленные на Сайте счетчики Yandex/Rambler/Google и т.п.
							</p>
							<p className={style.width}>
								5.6. При заполнении формы Обратной связи (п.3.3.) субъект
								заполняет окно с полем ввода номера телефона, своего Имени.
								После заполнения указанных параметров субъект подтверждает свое
								согласие на обработку персональных данных. В противном случае
								персональные данные не принимаются, не осуществляется их сбор,
								обработке не подлежат. После введения указанных данных Обратной
								связи Оператором производится звонок для уточнения причины
								посещения сайта, представление консультации по оказываемым
								услугам Оператором, продвигаемым им товарам. Дополнительные
								персональные данные у субъекта персональных данных не
								запрашиваются. После совершения обратной связи Оператор
								осуществляет обработку, хранение персональных данных, и
								уничтожение.
							</p>
							<p className={style.width}>
								5.7. Ссылки на другие (внешние) сайты могут быть размещены на
								сайте Оператора. Информация с этих сайтов не является
								продолжением либо дополнением материалов Оператора. Оператор не
								может нести ответственность за достоверность данных сайтов и
								используемых на сайтах услугах, использовании персональных
								данных.
							</p>
							<p>
								<strong>
									Сведения о реализуемых требованиях к защите персональных
									данных и способов достижения их защиты
								</strong>
							</p>
							<p className={style.width}>
								6.1. Оператор проводит следующие мероприятия: определяет угрозы
								безопасности персональных данных при их обработке, формирует на
								их основе модели угроз; осуществляет разработку на основе модели
								угроз системы защиты персональных данных, обеспечивающей
								нейтрализацию предполагаемых угроз с использованием методов и
								способов защиты персональных данных, предусмотренных для
								соответствующего класса информационных систем; осуществляет
								установку и ввод в эксплуатацию средств защиты информации в
								соответствии с эксплуатационной и технической документацией;
								осуществляет учет применяемых средств защиты информации,
								эксплуатационной и технической документации к ним, носителей
								персональных данных; осуществляет учет лиц, допущенных к работе
								с персональными данными в информационной системе; назначает лицо
								(круг лиц) ответственных за организацию обработки персональных
								данных; определяет места хранения персональных данных, а также
								места хранения персональных данных, оборудованных от
								несанкционированного проникновения; осуществляет контроль за
								соблюдением условий использования средств защиты информации,
								предусмотренных эксплуатационной и технической документацией;
								вправе инициировать разбирательство и составление заключений по
								фактам несоблюдения условий хранения носителей персональных
								данных, использования средств защиты информации, которые могут
								привести к нарушению конфиденциальности персональных данных или
								другим нарушениям, приводящим к снижению уровня защищенности
								персональных данных, разработку и принятие мер по предотвращению
								возможных опасных последствий подобных нарушений. В
								предусмотренных законом случаях обработка персональных данных
								осуществляется Оператором только с письменного согласия
								субъекта, а также допустимого законом равнозначного
								подтверждения согласия субъекта на обработку персональных
								данных.
							</p>
							<p className={style.width}>
								6.2. Для разработки и осуществления конкретных мероприятий по
								обеспечению безопасности персональных данных при их обработке в
								информационной системе Оператором или уполномоченным лицом
								ответственным является подразделение Оператора. Лица, доступ
								которых к персональным данным, обрабатываемым в информационной
								системе, необходим для выполнения служебных (трудовых)
								обязанностей, допускаются к соответствующим персональным данным
								на основании приказа, утвержденного Оператором. При обнаружении
								нарушений порядка предоставления персональных данных Оператор
								или уполномоченное лицо незамедлительно приостанавливают
								предоставление персональных данных пользователям информационной
								системы до выявления причин нарушений и устранения этих причин.
							</p>
							<p className={style.width}>
								<strong>Права и обязанности Оператора</strong>
							</p>
							<p>7.1. Оператор персональных данных вправе:</p>
							<p>• отстаивать свои интересы в суде;</p>
							<p>
								• предоставлять персональные данные субъектов третьим лицам,
								если это предусмотрено действующим законодательством РФ
								(налоговые, правоохранительные органы и др.);
							</p>
							<p>
								• отказывать в предоставлении персональных данных в случаях
								предусмотренных законодательством РФ;
							</p>
							<p>
								• использовать персональные данные субъекта без его согласия, в
								случаях предусмотренных законодательством РФ.
							</p>
							<p>
								<strong>
									Права и обязанности субъекта персональных данных
								</strong>
							</p>
							<p>8.1. Субъект персональных данных имеет право:</p>
							<p className={style.width}>
								• требовать уточнения своих персональных данных, их блокирования
								или уничтожения в случае, если персональные данные являются
								неполными, устаревшими, недостоверными, незаконно полученными
								или не являются необходимыми для заявленной цели обработки, а
								также принимать предусмотренные законом меры по защите своих
								прав;
							</p>
							<p className={style.width}>
								• требовать перечень своих персональных данных, обрабатываемых
								Оператором и источник их получения;
							</p>
							<p className={style.width}>
								• получать информацию о сроках обработки своих персональных
								данных, в том числе о сроках их хранения;
							</p>
							<p className={style.width}>
								• требовать извещения всех лиц, которым ранее были сообщены
								неверные или неполные его персональные данные, обо всех
								произведенных в них исключениях, исправлениях или дополнениях;
							</p>
							<p className={style.width}>
								• обжаловать в уполномоченный орган по защите прав субъектов
								персональных данных или в судебном порядке неправомерные
								действия или бездействия при обработке его персональных данных;
							</p>
							<p className={style.width}>
								• на защиту своих прав и законных интересов, в том числе на
								возмещение убытков и (или) компенсацию морального вреда в
								судебном порядке.
							</p>
							<p>
								<strong>Заключительные положения</strong>
							</p>
							<p className={style.width}>
								9.1. Настоящая Политика подлежит изменению, дополнению,
								пересмотру в случае появления новых законодательных актов и
								специальных нормативных документов по обработке и защите
								персональных данных. После изменения, дополнения, пересмотра
								положений настоящей Политики, ее актуализированная версия
								публикуется на сайте.
							</p>
							<p className={style.width}>
								Получить разъяснения по интересующим вопросам обработки
								персональных данных можно также, обратившись лично к Оператору,
								либо направив официальный запрос по Почте России по адресу: г.
								Санкт-Петербург, Индекс 191025, ул. Стремянная, дом 12, пом. 1Н.
							</p>
							<p>
								9.2. Настоящая Политика является внутренним документом
								Оператора, и подлежит размещению на официальном сайте{' '}
								{window.location.href}
							</p>
							<p>
								9.3. Контроль исполнения требований настоящей Политики
								осуществляется ответственным за обеспечение безопасности
								персональных данных Оператора.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
