import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header/Header.jsx';
import ModalBackCall from '../ModalBackCall/ModalBackCall.jsx';
import TopScreenVideo from '../TopScreenVideo/TopScreenVideo.jsx';
import Features from '../Features/Features.jsx';
import Quiz from '../Quiz/Quiz.jsx';
import StepsToWrite from '../StepsToWrite/StepsToWrite.jsx';
import ProblemSlider from '../ProblemSlider/ProblemSlider.jsx';
import PricesList from '../PricesList/PricesList.jsx';
import Comments from '../Comments/Comments.jsx';
import Sertificates from '../Sertificates/Sertificates.jsx';
import YandexMap from '../YandexMap/YandexMap.jsx';
import Footer from '../Footer/Footer.jsx';
import Preloading from '../Preloading/Preloading.jsx';
import SignOnline from '../SignOnline/SignOnline.jsx';
import style from './index.module.scss';

export default function App({ landingData, isLoading }) {
	const [openModal, setOpenModal] = React.useState(false); // хук модального окна для записи

	console.log('landingData', landingData);

	let yandex;
	let jivo;

	try {
		yandex = landingData?.metrika[0]?.yandex;
		jivo = landingData?.metrika[0]?.jivo;
	} catch {
		yandex = '';
		jivo = '';
	}

	// функция изменения состояния модального окна
	const toggleModal = (event) => {
		event.stopPropagation();
		setOpenModal(!openModal);
	};

	return (
		<div className={style.app}>
			{!isLoading && landingData.head ? (
				<>
					{/* библиотека динамически подставляет данные в head */}
					<Helmet>
						{jivo && <script src={jivo} type='text/javascript' async />}
						{yandex && (
							<script
								src={window.ym(yandex, 'init', {
									clickmap: true,
									trackLinks: true,
									accurateTrackBounce: true,
									webvisor: true,
								})}
								type='text/javascript'
								async
							/>
						)}
					</Helmet>

					<Header landingData={landingData} toggleModal={toggleModal} />
					{openModal && (
						<ModalBackCall
							toggleModal={toggleModal}
							landingData={landingData}
						/>
					)}
					<TopScreenVideo landingData={landingData} toggleModal={toggleModal} />
					<Features />
					<Quiz landingData={landingData} />
					<SignOnline landingData={landingData} />
					<StepsToWrite toggleModal={toggleModal} />
					<ProblemSlider toggleModal={toggleModal} />
					<PricesList landingData={landingData} />
					<Comments landingData={landingData} />
					<Sertificates />
					<YandexMap landingData={landingData} />
					<Footer landingData={landingData} toggleModal={toggleModal} />
				</>
			) : (
				<Preloading />
			)}
		</div>
	);
}
