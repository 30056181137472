export const priceList = [
	{
		id: 1,
		name: 'Женский медицинский педикюр',
		price: 3990,
	},
	{
		id: 2,
		name: 'Мужской медицинский педикюр',
		price: 3990,
	},
	{
		id: 3,
		name: 'Полугодовой абонемент на педикюр',
		price: 18900,
	},
	{
		id: 4,
		name: 'Годовой абонемент на педикюр',
		price: 34990,
	},
];