import React from 'react';
import Carousel from 'better-react-carousel';
import style from './index.module.scss';
import Item from './Item/Item.jsx';
import ModalItem from './ModalItem/ModalItem.jsx';
import { images } from './dataSertificates';

export default function Sertificates() {
	const [openModal, setOpenModal] = React.useState(false); // модальное окно сертификатов
	const [dataModal, setDataModal] = React.useState({}); // данные для передачи в модалное окно

	// отрытие закрытие модалки + передача данных
	const toggleModalSlider = event => {
		setOpenModal(!openModal);
		setDataModal(event);
	};

	// кастомные стрелки
	const arrowLeft = () => (
		<span
			style={{
				position: 'absolute',
				top: ' calc(50% - 16.5px)',
				height: '30px',
				width: '30px',
				borderTop: '5px solid #d60623',
				borderRight: '5px solid #d60623',
				backgroundColor: '#ffffff',
				zIndex: '1',
				cursor: 'pointer',
				left: '0',
				right: '0',
				transform: 'rotate(225deg)',
			}}
		></span>
	);
	// кастомные стрелки
	const arrowRight = () => (
		<span
			style={{
				position: 'absolute',
				top: ' calc(50% - 18.5px)',
				height: '30px',
				width: '30px',
				borderTop: '5px solid #d60623',
				borderRight: '5px solid #d60623',
				backgroundColor: '#ffffff',
				zIndex: '1',
				cursor: 'pointer',
				left: 'auto',
				right: '5px',
				transform: 'rotate(45deg)',
			}}
		></span>
	);

	return (
		<>
			{openModal && (
				<ModalItem
					toggleModalSlider={toggleModalSlider}
					dataModal={dataModal}
				/>
			)}
			<div className={style.sertificates}>
				<div className={style.inner}>
					<div className={style.titleContainer}>
						<h2 className={style.title}>Гарантия качества</h2>
					</div>
					<div className={style.listWrapper}>
						<Carousel
							cols={4}
							rows={1}
							gap={10}
							hideArrow={true}
							loop
							arrowLeft={arrowLeft}
							arrowRight={arrowRight}
						>
							{images.map(sert => (
								<Carousel.Item key={sert.id}>
									<Item
										sert={sert}
										toggleModalSlider={toggleModalSlider}
										openModal={openModal}
									/>
								</Carousel.Item>
							))}
						</Carousel>
					</div>
				</div>
			</div>
		</>
	);
}
