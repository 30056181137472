import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import App from '../../components/App/App.jsx';
import Sent from '../SendPage/SentPage.jsx';
import Error from '../ErrorPage/ErrorPage.jsx';
import NotFound from '../NotFoundPage/NotFoundPage.jsx';

export default function Main() {
const navigation = useNavigate();
const [landingData, setLandingData] = React.useState({});
const [isLoading, setIsLoading] = React.useState(true);
const departmentId = process.env.DEPARTMENT_ID;

React.useEffect(() => {
	fetch(
		`https://onshorkin.com/i8fd90sd/v09cxvjksdf/xzs9/sda9/landing_data/${departmentId}/`
	)
		.then(response => response.json())
		.then(result => setLandingData(result))
		.catch(e => {
			console.log(e);
			navigation('/error');
		});
	setIsLoading(false);
}, [departmentId, navigation]);

	return (
		<Routes>
			<Route
				path='/'
				element={<App landingData={landingData} isLoading={isLoading} />}
			/>
			<Route path='/sent' element={<Sent landingData={landingData} />} />
			<Route path='/error' element={<Error landingData={landingData} />} />
			<Route path='/*' element={<NotFound landingData={landingData} />} />
		</Routes>
	);
}
