/* eslint-disable no-undef */
import React from 'react';
import logo from '../../assets/images/logo.svg';
import { getMaskPhone } from '../../utils/maskPhone';
import style from './index.module.scss';

export default function Footer({ landingData, toggleModal }) {
	const phoneMask = getMaskPhone(landingData.phone);
	let yandex;

	try {
		yandex = landingData?.metrika[0]?.yandex;
	} catch {
		yandex = '';
	}

	return (
		<section className={style.footer}>
			<div className={style.inner}>
				<div className={style.innerContainer}>
					<div className={style.logoContainer}>
						<img className={style.logo} src={logo} alt='logo'></img>
					</div>
					<div className={style.contacts}>
						<div className={style.title}>Есть вопросы? Звони!</div>
						<div className={style.phones}>
							<div
								onClick={() => ym(yandex, 'reachGoal', 'ClickPhone')}
								className={style.phoneContainer}
							>
								<div className={style.city}>{landingData.address}</div>
								<div
									onClick={toggleModal}
									className='mgo-number-29473'
									id={style.phone}
								>
									{phoneMask}
								</div>
							</div>
						</div>
					</div>
					{landingData.social && (
						<div className={style.socials}>
							<div className={style.socialsContainer}>
								<div className={style.title}>Следите за нами</div>
								<div className={style.list}>
									{landingData.social?.instagram && (
										<a
											href={landingData.social.instagram}
											className={style.instagram}
										>
											{' '}
										</a>
									)}
									{landingData.social?.vk && (
										<a href={landingData.social.vk} className={style.vk}>
											{' '}
										</a>
									)}
									{landingData.social?.facebook && (
										<a
											href={landingData.social.facebook}
											className={style.facebook}
										>
											{' '}
										</a>
									)}
									{landingData.social?.youtube && (
										<a
											href={landingData.social.youtube}
											className={style.youtube}
										>
											{' '}
										</a>
									)}
								</div>
							</div>
						</div>
					)}
					<div className={style.legal}>
						Имеются противопоказания. <br />
						Необходимо проконсультироваться со специалистом.
					</div>
				</div>
				<div className={style.legalContainer}>
					<div className={style.copyright}>
						{new Date().getFullYear()} &copy; &laquo;Санатера&raquo;
					</div>
					<div className={style.item}>{landingData.company_info}</div>
					<div className={style.item}>ИНН 7810840233</div>
					<div className={style.item}>ОГРН 1117847388713</div>
					<div className={style.item}>КПП 783801001</div>
					<div className={style.mailContainer}>
						<a className={style.mail} href={'mailto:' + landingData.email}>
							{landingData.email}
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}
