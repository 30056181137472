import problem1 from '../../assets/images/problems/problem1.jpg';
import problem2 from '../../assets/images/problems/problem2.jpg';
import problem3 from '../../assets/images/problems/problem3.jpg';
import problem4 from '../../assets/images/problems/problem4.jpg';
import problem5 from '../../assets/images/problems/problem5.jpg';
import problem6 from '../../assets/images/problems/problem6.jpg';
import problem7 from '../../assets/images/problems/problem7.jpg';
import problem8 from '../../assets/images/problems/problem8.jpg';

export const images = [
	{
		id: 1,
		img: problem1,
		title: 'Боль в ногах',
		description: `Мастер медицинского педикюра выявляет первопричину проблемы и борется с ней, а не симптомами. Такой подход позволяет навсегда распрощаться с болью.`,
	},
	{
		id: 2,
		img: problem2,
		title: 'Трещины стопы',
		description: `Трещины - одна из многочисленных проблем, которые могут настичь наши ноги, причиняя неприятности в течение длительного времени. Мастер медицинского педикюра убирает трещины и возвращает коже на ногах прежнюю молодость.`,
	},
	{
		id: 3,
		img: problem3,
		title: 'Повышенная сухость кожи',
		description: `Повышенная сухость кожи может быть опасным симптомом. Мастер медицинского педикюра проведет полную диагностику и устранит проблему.`,
	},
	{
		id: 4,
		img: problem4,
		title: 'Натоптыши',
		description: `При аппаратном педикюре кожа не срезается со стопы, а шлифуется, размягчитель наносится локально на поврежденное место. Причём аппарат позволяет удалять только действительно омертвевшие клетки, не затрагивая глубокого, живого слоя кожи.`,
	},
	{
		id: 5,
		img: problem5,
		title: 'Потливость ног',
		description: `Чаще всего очень сложно выявить первопричину повышенного потоотделения на ногах. Если игнорировать эту проблему, она может привести к более тяжелым последствиям. Мастер медицинского педикюра проводит диагностику и предлагает варианты решения проблемы.`,
	},
	{
		id: 6,
		img: problem6,
		title: 'Вросший ноготь',
		description: `Мастер медицинского педикюра гарантированно и без боли решит проблему вросшего и деформированного ногтя.`,
	},
	{
		id: 7,
		img: problem7,
		title: 'Стержневые и подногтевые мозоли',
		description: `Мастера работают фрезой. Такой подход позволяет максимально комфортно устранить проблему. Процедура не требует обезболивания и не вызывает болезненных ощущений.`,
	},
	{
		id: 8,
		img: problem8,
		title: 'Грибок ногтей',
		description: `При лечебном педикюре врач аккуратно снимает верхние слои пластины. Так устраняются видимые симптомы заболевания (желтые и белые полосы и пятна). Утолщенный в результате заболевания ноготь приобретает первоначальный вид. К тому же паразитирующие на пластине бактерии становятся беззащитными.`,
	},
];
