import React, { useState } from 'react';
import style from './SignOnline.module.scss';
import image from '../../assets/images/sign-online/inside.jpg';

const SignOnline = ({ landingData, inline }) => {
	const { department, type, available_for_online_booking } = landingData;
	// console.log('department', department);
	console.log('available_for_online_booking', available_for_online_booking);
	const handleLinkClick = (event) => {
		event.preventDefault(); // Отменяем стандартное поведение перехода по ссылке
		const url = new URL(event.target.href);
		url.searchParams.append('department', department.toString()); // Добавляем параметр department
		url.searchParams.append('type', type.toString()); // Добавляем параметр type
		window.location.href = url.href; // Переходим по обновленному URL
	};

	// const available_for_online_booking = true;

	return (
		<>
			{!inline && available_for_online_booking && (
				<div className={style.container}>
					<div className={style.img__box}>
						<img className={style.image} src={image} alt='' />
					</div>
					<div className={style.btnLink__wrapper}>
						<a className={style.btnLink} href='https://avtozapis.archi.bz/' alt='link online' onClick={handleLinkClick}>
							записаться онлайн
						</a>
					</div>
				</div>
			)}{' '}
			{inline && available_for_online_booking && (
				<a
					className={style.btnLink__inline}
					href='https://avtozapis.archi.bz/'
					alt='link online'
					onClick={handleLinkClick}
				>
					записаться онлайн
				</a>
			)}
		</>
	);
};

export default SignOnline;
